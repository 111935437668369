<template>
  <div class="d-flex flex-column h-100 w-100 align-items-start justify-content-start">

    <div class="d-flex flex-row align-items-center" style="width: 100%; margin-top: 20px; margin-left: 20px">
      <div class="d-flex flex-column" style="flex: 0 0 50%; padding-right: 10px;">
        <base-multiselect
            v-if="commands.length > 0"
            class="base-multiselect__item"
            :items="commands"
            :value="selectedCommand"
            :multiple="false"
            :labelProp="'name'"
            :closeOnSelect="true"
            :search="false"
            @select="(payload) => selectCommand(payload)"
        ></base-multiselect>
      </div>
      <button v-if="selectedCommand"
              @click="confirmCommand()"
              class="v-btn v-theme--ORANGE_THEME bg-primary v-btn--density-default v-btn--size-default v-btn--variant-flat"
              style="max-width: 120px; margin-left: 10px;"
      >
        {{ $t("lables.exec") }}
      </button>
    </div>

    <div v-if="showModal" class="modal-overlay">
      <div class="modal-content">
        <h4>{{ $t("lables.confirmCommand") }}</h4>
        <div class="button-container">
          <button @click="executeCommand" class="v-btn v-btn--flat v-theme--BLUE_THEME bg-primary v-btn--density-default v-btn--size-default v-btn--variant-elevated">{{ $t("buttons.confirm") }}</button>
          <button @click="cancelCommand" class="v-btn v-btn--flat v-theme--BLUE_THEME bg-primary v-btn--density-default v-btn--size-default v-btn--variant-elevated">{{ $t("buttons.cansel") }}</button>
        </div>
      </div>
    </div>

    <div v-if="commandOutput.length > 0" style="width: 100%; margin-top: 20px; padding: 0 20px;">
      <textarea
          :value="formattedCommandOutput"
          readonly
          style="width: 100%;  height: 600px; resize: none; outline: none;"
      ></textarea>
    </div>
  </div>
</template>

<script>
import EntityApi from "@/api/entityApi";
import BaseMultiselect from "@/components/BaseMultiselect_new.vue";

export default {
  name: "ConveniqCommand",
  components: {BaseMultiselect},
  props: {
    conveniqId: {
      type: [String],
      required: true
    }
  },
  computed: {
    formattedCommandOutput() {
      return this.commandOutput
          .map(obj => `${obj.date}\n${obj.response}\n\n`)
          .join('');
    }
  },
  data() {
    return {
      showModal: false,
      selectedCommand: null,
      commands: [],
      commandOutput: [],
    };
  },
  methods: {
    confirmCommand() {
      this.showModal = true;
    },
    executeCommand() {
      this.showModal = false;
      if (this.selectedCommand?.id) {
        this.execCommand(this.selectedCommand.id)
      }
    },
    cancelCommand() {
      this.showModal = false;
    },
    async execCommand(commandId) {
      let resp = await EntityApi.executeConveniqCommand(this.conveniqId, commandId);
      if (resp && typeof resp === 'object' && 'status' in resp && resp.status) {
        this.$notify({group: "alert", type:"info", title: this.$t('lables.success'), text: this.$t('lables.successfulExec')}, 3000);
        this.commandOutput.push({
          date: new Date().toISOString(),
          response: JSON.stringify(resp)
        });
      } else {
        this.$notify({group: "alert", type:"error", title: this.$t('lables.opps'), text: this.$t('lables.failedExec')}, 3000);
      }
    },
    async getCommands() {
      try {
        const locale = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
        const response = await EntityApi.getEntityItems('MqttCommand');
        this.commands = response.map(command => ({
          id: command.id,
          name: command.labels?.[locale] || 'Unnamed',
        }));
        console.log(this.commands);
      } catch (error) {
        console.error('Error fetching commands:', error);
        this.commands = [];
      }
    },
    selectCommand(value){
      this.selectedCommand = value;
    },
  },
  mounted() {
    this.getCommands();
  },
};
</script>


<style scoped>

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  z-index: 1001;
}

.button-container {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

</style>